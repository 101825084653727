import { FC, ReactNode } from 'react';
import { Box, Theme } from '@mui/material';

interface IListingCardWrapper {
    children: ReactNode;
}

export const ListingCardWrapper: FC<IListingCardWrapper> = ({ children }) => {
    return <Box paddingY={4} borderBottom={(theme: Theme) => `1px solid ${theme.palette.dividerTransparent}`}>
        {children}
    </Box>;
};
