import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface ICardTitle {
    title: string;
}

export const CardTitle: FC<ICardTitle> = ({ title }) => {
    return <Text mobileVariant='h5' variant='h4' component='h2' semibold mb={{ xs: .5, md: 1 }}>{title}</Text>;
};
