import { useCallback, useEffect, useState } from 'react';
import { realEstateLocationApi } from 'modules/api/client';
import { ILocation } from 'components/page/realEstate/common/interfaces/ILocation';
import {
    RealEstateAgencyListingLocationDtoToIlocationMapper
} from 'components/page/realEstate/common/helpers/mappers/RealEstateAgencyListingLocationDtoToIlocationMapper';

interface IReturn {
    isFetching: boolean;
    locations: ILocation[];
}

export const useLocations = (): IReturn => {
    const [locations, setLocations] = useState<ILocation[]>([]);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const getLocations = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await realEstateLocationApi.getLocations();
            setLocations(response.data.items.map(location => RealEstateAgencyListingLocationDtoToIlocationMapper(location)));
        } catch (error) {
            setLocations([]);
        } finally {
            setIsFetching(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await getLocations();
        })();
    }, [getLocations]);

    return {
        locations,
        isFetching,
    };
};
