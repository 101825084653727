import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { Firm } from 'modules/theme/components/assets/userBudges/firm/Firm';


interface IPreviewImg {
    imgUrl: string;
}

export const PreviewImg: FC<IPreviewImg> = ({ imgUrl }) => {
    return <Box
        p={1}
        height='100%'
        display='flex'
        borderRadius={1}
        border='1px solid'
        alignItems='center'
        position='relative'
        justifyContent='center'
        borderColor={(theme: Theme) => theme.palette.dividerTransparent}
    >
        <Firm logoUrl={imgUrl} width='100%' height='100%'/>
    </Box>;
};
