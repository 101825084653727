import { FC } from 'react';
import { Box } from '@mui/material';
import Image from 'components/image/Image';

interface IPhotoItem {
    photoUrl: string;
    width: number;
    height: number;
}

export const PhotoItem: FC<IPhotoItem> = ({ photoUrl, width, height }) => {
    return <Box
        width='100%'
        height='100%'
        borderRadius={1}
        overflow='hidden'
        position='relative'
    >
        <Image
            width={width}
            height={height}
            src={photoUrl}
            alt='Foto inzerátu'
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }}
        />
    </Box>;
};
