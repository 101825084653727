import { RealEstateAgencyListingLocationDto } from 'modules/api/generated';
import { ILocation } from 'components/page/realEstate/common/interfaces/ILocation';

export const RealEstateAgencyListingLocationDtoToIlocationMapper = (locationDto: RealEstateAgencyListingLocationDto): ILocation => {
    const { nin, name, sefName, locationIds, isParent } = locationDto;

    return {
        id: locationDto.id,
        name: name,
        sefName: sefName,
        isParent: isParent,
        allOtherLocations: nin,
        locationIds: locationIds,
    };
};
