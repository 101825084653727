import { FC } from 'react';
import { ILocation } from 'components/page/realEstate/common/interfaces/ILocation';
import { IOption } from 'modules/theme/components/fields/select/interfaces/IOption';
import { AutocompleteSelect } from 'modules/theme/components/fields/select/AutocompleteSelect';
import MappinOutline from 'modules/theme/components/icons/basic/MappinOutline.svg';

interface ILocationProps {
    isLoading: boolean;
    locations: ILocation[];
    selectedLocation: ILocation;
    onSelectedLocation: (selectedLocationId: string) => void;
}

export const Location: FC<ILocationProps> = ({
    isLoading,
    locations,
    selectedLocation,
    onSelectedLocation
}) => {

    const options: IOption[] = locations.map(location => ({
        name: location.name,
        isParent: location.isParent,
        value: location.id,
    }));

    return <AutocompleteSelect
        options={options}
        ariaLabel='Lokalita'
        icon={MappinOutline}
        disabled={isLoading}
        placeholder='Zadajte okres alebo kraj ...'
        selectedValue={selectedLocation?.id || null}
        onChange={id => {
            onSelectedLocation(id);
        }}
    />;
};
