import { FC } from 'react';
import { Grid } from '@mui/material';
import {
    PhotoItem
} from 'modules/theme/components/agency/common/components/advertisementsInfo/photos/groupPhotos/partials/PhotoItem';
import { IAdvertisementsPhoto } from 'modules/theme/components/agency/common/interfaces/IAdvertisementsPhoto';

interface IGroupPhotos {
    advertisementsPhotos?: IAdvertisementsPhoto[]
}

export const GroupPhotos: FC<IGroupPhotos> = ({ advertisementsPhotos }) => {

    const photos = advertisementsPhotos.map((info) => info.urls.map(url => ({
        advertisementId: info.advertisementId,
        url: url,
    }))).flat();


    return <Grid
        gap={.5}
        container
        display='flex'
        borderRadius={1}
        flexWrap='nowrap'
        data-test-id='groupPhotos'
    >
        {photos.map(({ url }, index) =>
            <Grid
                item
                xs={3}
                sm={2}
                md={1}
                height={60}
                key={`${url}-${index}`}
                display={{
                    xs: index > 3 ? 'none' : 'block',
                    md: 'block'
                }}
            >
                <PhotoItem photoUrl={url} width={98} height={60}/>
            </Grid>
        )}
    </Grid>;
};
