import { FC } from 'react';
import { Box, Palette, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import { ETransactionTypes } from 'modules/theme/components/agency/common/enums/ETransactionTypes';
import { ILemmatizeWordAccordingToNumber } from 'modules/lemmatize/ILemmatizeWordAccordingToNumber';

interface INumberOfAdvertisementsInfoBox {
    totalCount: number;
    transactionType: ETransactionTypes,
}

export const NumberOfAdvertisementsInfoBox: FC<INumberOfAdvertisementsInfoBox> = ({ transactionType, totalCount }) => {
    const { palette } = useTheme();

    const lemmatizeWords: ILemmatizeWordAccordingToNumber = {
        eqZero: 'bez ponuky',
        eqOne: 'ponuka',
        betweenTwoAndFourInclusive: 'ponuky',
        moreThenFour: 'ponúk',
        withoutNumberIfZero: true,
    };

    const totalCountTerm = lemmatizeWordAccordingToNumber(totalCount, lemmatizeWords);

    return <Box
        gap={.5}
        width='100%'
        height='100%'
        display='flex'
        paddingX={2.25}
        borderRadius={1}
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        paddingY={{ xs: 1.5, md: 2 }}
        bgcolor={palette.backgroundSecondaryElevated.main}
    >

        <Text variant='label2' color={(palette: Palette) => palette.labelSecondary} center>
            {transactionType}:
        </Text>

        <Text mobileVariant='body3' variant='body2' semibold>
            {totalCountTerm}
        </Text>
    </Box>;
};
