import { FC } from 'react';
import { Grid } from '@mui/material';
import {
    INumberOfTotalAdvertisementsInfo
} from 'modules/theme/components/agency/common/interfaces/INumberOfTotalAdvertisementsInfo';
import {
    PreviewImg
} from 'modules/theme/components/agency/common/components/advertisementsInfo/counts/partials/PreviewImg';
import {
    NumberOfAdvertisementsInfoBox
} from 'modules/theme/components/agency/common/components/advertisementsInfo/counts/partials/NumberOfAdvertisementsInfoBox';

interface INumberOfAdvertisementsInfo {
    previewImgUrl?: string;
    numberOfTotalAdvertisementsInfo: INumberOfTotalAdvertisementsInfo[];
}

export const NumberOfAdvertisementsInfo: FC<INumberOfAdvertisementsInfo> = ({
    previewImgUrl,
    numberOfTotalAdvertisementsInfo
}) => {
    return (
        <Grid container spacing={1} height='100%' columns={14} data-test-id='numberOfAdvertisementsInfo'>
            <Grid item xs={4}>
                <PreviewImg imgUrl={previewImgUrl}/>
            </Grid>

            {numberOfTotalAdvertisementsInfo.map(({ transactionType, numberOfTotal }: INumberOfTotalAdvertisementsInfo) =>
                <Grid item xs={5} key={transactionType}>
                    <NumberOfAdvertisementsInfoBox
                        totalCount={numberOfTotal}
                        transactionType={transactionType}
                    />
                </Grid>
            )}

        </Grid>
    );
};
