import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';


interface IHeadline {
    headline: string;
}

export const Headline: FC<IHeadline> = ({ headline }) => {
    return <Text semibold variant='h3' mobileVariant='h4' component='h1'>{headline}</Text>;
};
