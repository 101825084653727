import { FC } from 'react';
import { SwitchFilter } from 'modules/theme/components/filter/switchFilter/SwitchFilter';
import { IOption } from 'modules/theme/components/filter/switchFilter/interfaces/IOption';
import {
    ERealEstateListingSubpages
} from 'components/page/realEstate/common/enums/ERealEstateListingSubpages';

interface AgencyAgentSwitcher {
    onSelect: (selectedValue: ERealEstateListingSubpages) => void;
    currentSubpage: ERealEstateListingSubpages;
}

export const AgencyAgentSwitcher: FC<AgencyAgentSwitcher> = ({
    currentSubpage,
    onSelect,
}) => {
    const switcherOptions: IOption[] = [
        {
            name: 'Realitné kancelárie',
            value: ERealEstateListingSubpages.AGENCY,
            isDisabled: currentSubpage === ERealEstateListingSubpages.AGENCY,
        },
        {
            name: 'Realitní makléri',
            value: ERealEstateListingSubpages.AGENT,
            isDisabled: currentSubpage === ERealEstateListingSubpages.AGENT,
        },
    ];

    return (
        <SwitchFilter
            uniformWidth
            onChange={onSelect}
            options={switcherOptions}
            activeOptionValue={currentSubpage}
        />
    );
};
